import redirectUrlConfig from "@/assets/urls-v6.json";

export default defineNuxtRouteMiddleware(async (to, from) => {
  // Add check to run only on server side
  if (process.client) return;

  const nuxtApp = useNuxtApp();
  const locale = nuxtApp?.$i18n?.locale.value ?? "ar";
  const IDToCheck = +to.params.id;

  let movieURL = "";
  let seriesURL = "";

  const headersToPass = {
    ...nuxtApp.ssrContext?.event.node.req.headers,
    "App-Locale": locale,
  };
  const findConfig = redirectUrlConfig.find((cn) => cn.ID === IDToCheck);

  if (findConfig) {
    // No need to add locale as locale is present in the excel sheet JSON
    return navigateTo(findConfig.URL, {
      redirectCode: 301,
    });
  } else {
    // Check in API
    const [movie, series] = await Promise.all([
      $fetch(
        `${process.env.BASE_URL}/api/biz/video/aggregate/detail?assetExternalId=${IDToCheck}&type=Movie&contentType=Movie`,
        {
          headers: headersToPass,
        }
      ),
      $fetch(
        `${process.env.BASE_URL}/api/biz/video/aggregate/detail?externalSeriesId=${IDToCheck}&type=Series&contentType=Series`,
        {
          headers: headersToPass,
        }
      ),
    ]);

    // Build Urls
    if (movie?.code === "SUCCESS") {
      const thisMovieHasTvShowReference =
        "tvShowReference" in movie?.response?.aggregatedVideo &&
        Object.keys(movie?.response?.aggregatedVideo?.tvShowReference).length >
          0;
      if (thisMovieHasTvShowReference) {
        movieURL = useEpisodePath(movie?.response?.aggregatedVideo).asString;
      } else {
        movieURL = `/movies/${movie?.response?.aggregatedVideo?.assetExternalId}/${movie?.response?.aggregatedVideo?.name}`;
      }
    }
    if (series?.code === "SUCCESS") {
      seriesURL = `/shows/${series?.response?.tvShowSeries?.externalId}/${series?.response?.tvShowSeries?.name}/season-1?sort=desc`;
    }

    // Redirect
    if (seriesURL) {
      return navigateTo(nuxtApp.$localePath(seriesURL, locale), {
        redirectCode: 301,
      });
    } else if (movieURL) {
      return navigateTo(nuxtApp.$localePath(movieURL, locale), {
        redirectCode: 301,
      });
    } else {
      return navigateTo(nuxtApp.$localePath("/", locale), {
        redirectCode: 404,
      });
    }
  }
});
